<template lang="pug">
div(ref='el' class='simple-list-container')
  overlay(:visible='loading')
  div(ref='list' class='simple-list')
    slot
      div(v-for='(item, index) in transformedItems' :key='item.id' :data-id='item.id' :data-index='index' class='simple-list__item')
        slot(name='item' :data='item' :items='transformedItems' :selected='itemsState[item.id]' :index='index' :toggle='toggle')
          | {{ item.id }}

  empty(v-if='!items || items.length === 0' class='simple-list__empty')
</template>

<script lang="ts">
import { unique } from 'radash'
import { defineComponent, ref, computed, type PropType, watch, type SetupContext } from 'vue'

export interface IListItem {
  id: string
  [fields: string]: any
}

export interface IListSelectState {
  [id: string]: boolean
}

const SimpleList = defineComponent({
  props: {
    modelValue: Array as PropType<string[]>,
    items: Array,
    itemKey: { type: String, default: 'id' },
    loading: { type: Boolean, default: false },
    multiselect: { type: Boolean, default: true },
  },
  setup(props, context: SetupContext) {
    const highlightedElements = ref<string[]>([])
    const localValue = ref<string[]>([])
    const el = ref<HTMLElement | null>(null)

    watch(
      () => props.modelValue!,
      (value: string[]) => (localValue.value = value),
    )

    const transformedItems = computed<IListItem[]>(() =>
      props.items!.map((item: any) => ({ ...item, id: item[props.itemKey] })),
    )

    const itemsState = computed<IListSelectState>(() =>
      localValue.value.reduce((state: IListSelectState, id: string) => ({ ...state, [id]: true }), {}),
    )

    const toggle = (id: string) => {
      setTimeout(() => {
        const select: boolean = !localValue.value.includes(id)
        localValue.value = select
          ? unique([...localValue.value, ...highlightedElements.value])
          : highlightedElements.value.length > 1
            ? localValue.value.filter((i: string) => !highlightedElements.value.includes(i))
            : localValue.value.filter((i: string) => id !== i)

        context.emit('update:modelValue', localValue.value)
      }, 20)
    }

    return {
      el,
      transformedItems,
      toggle,
      itemsState,
    }
  },
})
export default SimpleList
</script>
<style lang="stylus">
@import '../styles/variables.styl'
.simple-list-container
  position: relative

.simple-list
  display: flex
  flex-flow: column
  &__empty
    padding-left: var(--bleed-left)
    padding-right: var(--bleed-right)
  &__item
    width: 100%
    flex-flow: column
    display: flex
</style>
